<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2021-05-20 11:50 -->
<template>
  <div>
    <Box
      ref="elBox"
      :disabled="!availability"
      :cashRegisterId="cashRegisterId"
    ></Box>
  </div>
</template>
<script>
// import { AdminService } from "src/lt/admin-module";
import Box from "./Box.vue";
import { hasPermissionTo } from "src/lt/utils/permissionStorage";
export default {
  components: {
    Box
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    cashRegisterId: null,
    availability: false
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    this.cashRegisterId = this.$store.state.user.cash_register_id;
    this.availability = true;
    this.availability = hasPermissionTo("mc_edit");
    setTimeout(() => {
      this.$refs.elBox.getBox(true);
    }, 300);
  },

  methods: {
    //
  }
};
</script>

<style scoped></style>
